import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { routesMap } from './routes/routesMap'

import allRoutes from './routes/allRoutes.json'

const Router = () => {
  return (
    <>
      <Routes>
        {Object.values(allRoutes).map((route, index) => (
          <Route key={index} path={route} element={routesMap[route]} />
        ))}
        <Route path='*' element={<Navigate to={allRoutes.Homepage} replace />}></Route>
      </Routes>
    </>
  )
}

export default Router
