import React, { useState } from "react";

import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile.svg";
import { ReactComponent as HamburgerIcon } from "../../assets/icons/hamburger.svg";

import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow_icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout_icon.svg";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { $userId } from "../../entities";
import { config } from "../../config";

const Navbar = () => {
  const userId = useStore($userId);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const name = localStorage.getItem("name");

  return (
    <header className="navbar">
      <Logo onClick={() => navigate("/homepage")} className="navbar__logo" />
      <div className="navbar__mobile">
        <HamburgerIcon
          style={{ cursor: "pointer" }}
          onClick={() => setIsMenuOpen((prev) => !prev)}
          className="navbar__hamburger"
        />
        <ProfileIcon className="navbar__mobile-profile" />
        {isMenuOpen && (
          <div className="navbar__mobile-menu">
            <ul className="navbar__mobile-menu-wrapper">
              <li
                onClick={() => {
                  userId ? navigate("/my-projects") : navigate("/sign-up");
                }}
                className="navbar__mobile-menu-link"
              >
                MY PROJECTS
              </li>
              <li
                onClick={() => {
                  userId ? navigate("/upload") : navigate("/sign-up");
                }}
                className="navbar__mobile-menu-link"
              >
                UPLOAD A PLAN
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="navbar__wrapper">
        {config.dev && (
          <span onClick={() => navigate("/status")} className="navbar__link">
            STATUS
          </span>
        )}
        <span
          onClick={() => {
            userId ? navigate("/my-projects") : navigate("/sign-up");
          }}
          className="navbar__link"
        >
          MY PROJECTS
        </span>
        <a className="navbar__link" style={{ textDecoration: 'none', color: '#B8B8B8' }} href={`${location.origin}/3d-plans-unreal-renderer.zip`} target="_blank" download>LOAD BUILD</a>
        <span
          onClick={() => {
            userId ? navigate("/upload") : navigate("/sign-up");
          }}
          className="navbar__link"
        >
          UPLOAD A PLAN
        </span>
        <div className="navbar__profile-wrapper">
          <img
            src="https://source.unsplash.com/300x300"
            alt=""
            className="navbar__profile-img"
          />
          <div
            onClick={() => setIsMenuOpen((prev) => !prev)}
            className="navbar__profile-dropdown"
          >
            {name} <ArrowIcon className="navbar__arrow-icon" />{" "}
            {isMenuOpen && (
              <div className="navbar__logout">
                <p className="navbar__logout-text">
                  Log Out <LogoutIcon />
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
