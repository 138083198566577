import React, { useEffect, useState } from "react";
import { useStore } from "effector-react";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload_icon.svg";
import Navbar from "../../components/Navbar/Navbar";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow_right.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash_icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross_icon.svg";

import { $task, changeTask } from "../../entities";
import { Task } from "../../entities/task";
import { validateFile } from "../../utils/helpers";

const DesignUpload = () => {
  const [file, setFile] = useState<File | null>(null);
  const task = useStore($task);
  const navigate = useNavigate();

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const drawing = event.target.files?.[0];
    if (drawing) {
      setFile(drawing);
    }
  };

  const handleClearFile = () => {
    const input = document.querySelector(".upload__input") as HTMLInputElement;
    if (input) {
      input.value = "";
    }
    setFile(null);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const drawing = e.dataTransfer.files?.[0];
    setFile(drawing);
  };

  const handleUploadDivClick = () => {
    const input = document.querySelector(".upload__input") as HTMLInputElement;
    if (input) {
      input.click();
    }
  };

  useEffect(() => {
    changeTask(new Task(uuidv4()));
  }, []);

  return (
    <>
      <Navbar />
      <div className="upload__container">
        <div className="upload__text-wrapper">
          <h2 className="upload__title">Please upload your layout</h2>
        </div>
        <form className="upload__input-container">
          <div
            onClick={handleUploadDivClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            className="upload__input-wrapper"
          >
            <UploadIcon className="upload__upload-icon" />
            <h3 className="upload__input-heading">
              Drag & drop file or Browse
            </h3>
            <p className="upload__input-body">
              Supported files: drawings, schemes, drafts, measure etc.
            </p>
            <input
              onChange={onChangeHandler}
              className="upload__input"
              type="file"
              accept=".jpg,.png"
            />
          </div>
          {file && (
            <>
              <div className="upload__files-container">
                <ul className="upload__files-list">
                  <li className="upload__file-wrapper">
                    {(() => {
                      const validationResult = validateFile(file);
                      return (
                        <>
                          {validationResult.value === false && (
                            <p className="upload__error">
                              {validationResult.message}
                            </p>
                          )}
                          <div
                            className={`upload__file ${
                              validationResult.value === false &&
                              "upload__file--err-clr"
                            }`}
                          >
                            {file.name}
                            {validationResult.value ? (
                              <TrashIcon onClick={handleClearFile} />
                            ) : (
                              <CrossIcon
                                onClick={handleClearFile}
                                style={{ marginRight: ".3rem" }}
                              />
                            )}
                          </div>
                        </>
                      );
                    })()}
                  </li>
                </ul>
              </div>
              <button
                type="button"
                onClick={() => {
                  task.addDrawing(file);
                  navigate("/project-preview");
                }}
                disabled={!validateFile(file).value}
                className="custom-btn"
              >
                NEXT <ArrowRightIcon />
              </button>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default DesignUpload;
