import { useCallback, useEffect, useState } from "react";
import { saveAs } from 'file-saver';
import { loadStatus } from "../../services";
import { config } from "../../config";
import { useNavigate } from "react-router-dom";

export default function Status() {
    const navigate = useNavigate();
    const [files, setFiles] = useState<Array<File>>([]);
    const [load, setLoad] = useState(false);
    const [status, setStatus] = useState({});

    useEffect(() => void fetchStatus(), []);

    useEffect(() => {
        if (!config.dev) navigate('/homepage');
    }, []);

    const fetchStatus = useCallback(async () => {
        const status = await loadStatus();
        setStatus(status);
    }, []);

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files) {
            setFiles([...files]);
        }
    };

    const handleSendImages = async () => {
        setLoad(true);
        try {
            const formData = new FormData();
            files.forEach(file => formData.append('generator', file))


            const response = await fetch('http://46.138.246.77:17779', {
                method: "POST",
                body: formData,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            });
            const blob = await response.blob();

            const filename = 'download.zip'
            saveAs(blob, filename);
        } catch (e) {
            console.log(e);
        } finally {
            setLoad(false);
        }
    }

    return (
        <div style={{
            display: 'flex',
            margin: '0 auto',
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'center',
            textAlign: 'right',
        }}>
            <button onClick={fetchStatus} style={{
                backgroundColor: 'transparent',
                borderRadius: 3,
                border: '1px solid black',
                marginBottom: 15,
                cursor: 'pointer',
            }}>UPDATE</button>
            {
                Object.entries(status).map(queue => (
                    <div key={queue[0]} style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginBottom: 2,
                    }}>
                        <div>{queue[0]}</div>
                        <div style={{
                            borderRadius: '50%',
                            backgroundColor: queue[1] ? 'green' : 'red',
                            width: 10,
                            height: 10,
                            marginLeft: 7,
                        }} />
                    </div>
                ))
            }
            <input type="file" multiple={true} placeholder="kek" style={{ marginTop: 20 }} onChange={onChangeHandler} />
            <button disabled={load || !files.length} onClick={handleSendImages} style={{ marginTop: 20, cursor: 'pointer' }}>{load ? 'Ждите' : 'Отправить'}</button>
        </div>
    );
}
