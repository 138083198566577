export function saveState<T>(state: T, key: string) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(key, serializedState)
  } catch (err) {
    console.log(err)
  }
}

export function loadState<T>(initialState: T, key: string): T | undefined {
  try {
    const serializedState = localStorage.getItem(key)
    if (serializedState === null) {
      localStorage.setItem(key, JSON.stringify(initialState))
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}
