import React from "react";

import { ReactComponent as MoveIcon } from "../../../assets/icons/move_icon.svg";
import { ReactComponent as StartTourIcon } from "../../../assets/icons/start_tour_icon.svg";
import { ReactComponent as FurnitureIcon } from "../../../assets/icons/furniture_icon.svg";
import { useAppDispatch } from "../../../redux/hooks";
import { setIsFurnitureListModalOpen } from "../../../redux/slices/modalSlice";

const Menu = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <ul className="menu__container">
        <li className="menu__option">
          <MoveIcon />
          <p className="menu__option-text">Move</p>
        </li>
        {/* <li
          onClick={() =>
            dispatch(
              setIsFurnitureListModalOpen({
                isFurnitureListModalOpen: true,
              }),
            )
          }
          className='menu__option'
        >
          <FurnitureIcon />
          <p className='menu__option-text'>
            Choose <br />
            furniture
          </p>
        </li> */}
        <li className="menu__option">
          <StartTourIcon />
          <p className="menu__option-text">
            Start <br /> tour
          </p>
        </li>
      </ul>
    </>
  );
};

export default Menu;
