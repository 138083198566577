import React, { useEffect, useState } from "react";
import { useStore } from "effector-react";
import Navbar from "../../components/Navbar/Navbar";

import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow_right.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import styleImg from "../../assets/images/style_img.png";
import styleImg2 from "../../assets/images/style2_img.png";

import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { $task, pushTask } from "../../entities";
import ContentLoader from "react-content-loader";
import { relative } from "path";
import { error } from "console";

type StyleProps = {
  id: string;
  img: string;
  name: string;
  isSelected: boolean;
};

const ChooseStyle = () => {
  const [styles, setStyles] = useState<StyleProps[]>([
    {
      id: uuidv4(),
      img: styleImg,
      name: "American light",
      isSelected: false,
    },
    {
      id: uuidv4(),
      img: styleImg2,
      name: "Style is locked",
      isSelected: false,
    },
    {
      id: uuidv4(),
      img: styleImg2,
      name: "Style is locked",
      isSelected: false,
    },
    {
      id: uuidv4(),
      img: styleImg2,
      name: "Style is locked",
      isSelected: false,
    },
    {
      id: uuidv4(),
      img: styleImg2,
      name: "Style is locked",
      isSelected: false,
    },
    {
      id: uuidv4(),
      img: styleImg2,
      name: "Style is locked",
      isSelected: false,
    },
    {
      id: uuidv4(),
      img: styleImg2,
      name: "Style is locked",
      isSelected: false,
    },
    {
      id: uuidv4(),
      img: styleImg2,
      name: "Style is locked",
      isSelected: false,
    },
    {
      id: uuidv4(),
      img: styleImg2,
      name: "Style is locked",
      isSelected: false,
    },
  ]);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const task = useStore($task);

  useEffect(() => {
    const btn = document.querySelector(".btn") as HTMLButtonElement;
    if (btn) {
      styles.find((style) => style.isSelected === true)
        ? (btn.disabled = false)
        : (btn.disabled = true);
    }
  }, [styles]);

  const handleSelect = (id: string, index: number) => {
    if (index === 0) {
      setStyles((prevStyles) =>
        prevStyles.map((style) => ({
          ...style,
          isSelected: style.id === id ? true : false,
        }))
      );
    }
  };

  function next() {
    task.start();
    pushTask(task);
    navigate("/render-sent-message");
  }

  useEffect(() => {
    if (task.id === "11") navigate("/my-projects");
  }, []);

  return (
    <>
      <Navbar />
      <div className="style__container">
        <h1 className="style__title">Choose a style of furniture</h1>
        <div className="style__styles-container">
          {styles.map((style, index) => (
            <div
              key={index}
              onClick={() => handleSelect(style.id, index)}
              className={`style__style ${style.isSelected && "selected-style"}`}
            >
              <img
                src={style.img}
                alt={style.name}
                className="style__style-img"
                loading="lazy"
                onLoad={() => {
                  setImageLoaded(true);
                }}
              />
              {!imageLoaded ? (
                <ContentLoader
                  speed={2}
                  width={307}
                  height={280}
                  viewBox="0 0 307 280"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  style={{ position: "relative" }}
                >
                  <rect rx={20} ry={20} width="307" height="265" />
                </ContentLoader>
              ) : (
                <>
                  {index > 0 && <LockIcon />}
                  <span className="style__style-name">{style.name}</span>
                </>
              )}
            </div>
          ))}
        </div>
        <div className="style__buttons-wrapper">
          <button
            onClick={() => navigate("/project-preview")}
            className="custom-btn"
          >
            <ArrowRightIcon /> BACK
          </button>
          <button onClick={next} className="custom-btn">
            NEXT <ArrowRightIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default ChooseStyle;
