import React from "react";

import { useForm, SubmitHandler } from "react-hook-form";
import { useAsyncFn } from "react-use";
import MobileFooter from "../components/MobileFooter";

import { ReactComponent as EyeIcon } from "../../../assets/icons/password_eye.svg";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/icons/google_icon.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { loginFx } from "../../../entities";

type Inputs = {
  email: string;
  password: string;
};

const SignIn = () => {
  const navigate = useNavigate();
  const [request, doRequest] = useAsyncFn(loginFx, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<Inputs>({ mode: "onChange" });

  const onSubmit: SubmitHandler<Inputs> = async ({ email, password }) => {
    const result = (await doRequest({ login: email, password })) as any;
    if (result instanceof Error) return;
    navigate("/upload");
  };

  function togglePasswordVisibility(): void {
    const input = document.getElementById(
      "password-input"
    ) as HTMLInputElement | null;
    if (input) {
      input.type = input.type === "password" ? "text" : "password";
    }
  }

  return (
    <div className="sign-up">
      <Logo className="sign-up__logo" />
      <div className="sign-up__outer-container">
        <h1 className="sign-up__mobile-title">Log in</h1>
        <h1 className="sign-up__desktop-title">Log in here</h1>
        <div className="sign-up__inner-container">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="sign-up__input-wrapper">
              <label className="sign-up__input-label" htmlFor="email">
                Email
              </label>
              <input
                autoComplete="off"
                className={`sign-up__input ${
                  errors.email && "sign-up__input--error_clr"
                }`}
                type="email"
                {...register("email", {
                  required: { value: true, message: "This field is required!" },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Your Email was entered incorrectly!",
                  },
                })}
              />
              {errors.email && (
                <span className="sign-up__validation-error">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div className="sign-up__input-wrapper">
              <label className="sign-up__input-label" htmlFor="password">
                Password
              </label>
              <input
                autoComplete="off"
                id="password-input"
                className={`sign-up__input ${
                  errors.password && "sign-up__input--error_clr"
                }`}
                type="password"
                {...register("password", {
                  required: { value: true, message: "This field is required!" },
                })}
              />
              <EyeIcon
                onClick={() => togglePasswordVisibility()}
                className="sign-up__eye-icon"
              />
              {errors.password && (
                <span className="sign-up__validation-error">
                  {errors.password.message}
                </span>
              )}
            </div>
            <button
              type="submit"
              disabled={!isDirty || !isValid || request.loading}
              className="btn btn--sm"
            >
              Sign In
            </button>
          </form>
        </div>
        {/* <div className="sign-up__google-wrapper">
          <p className="sign-up__google-text">Or sign up with</p>
          <GoogleIcon className="sign-up__google-icon" />
        </div> */}
      </div>
      <MobileFooter />
    </div>
  );
};

export default SignIn;
