import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { loadState } from '../../utils/localStorage'
import { FileState, SerializableFile } from '../types'

const initialState: FileState = {
  file: null,
}

const persistedState = loadState<FileState>(initialState, 'file')

const fileSlice = createSlice({
  name: 'file',
  initialState: persistedState || initialState,
  reducers: {
    setFile: (state, action: PayloadAction<SerializableFile | null>) => {
      state.file = action.payload
    },
    deleteFile: (state) => {
      state.file = null
    },
    clearFile: (state) => {
      state.file = null
    },
  },
})

export const { setFile, deleteFile, clearFile } = fileSlice.actions

export default fileSlice.reducer
