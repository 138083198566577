import EventEmitter from 'eventemitter3';
import { $userId } from '.';
import { createTask, delay, loadJSON, loadTask, startTask, uploadImage } from '../services';
import moment, { Moment } from 'moment';
import { config } from '../config';
import { useEffect, useState } from 'react';

export enum ObjectType {
    BATH = 'Bath',
    DRYER = 'Dryer',
    WASHING_MACHINE = 'Washing Machine',
    COUNTERTOP = 'Countertop',
    TOILET = 'Toilet',
    STOVE = 'Stove',
    REFRIGIRATOR = 'Refrigirator',
    SINK = 'Sink',
    KITCHEN_SINK = 'Kitchen Sink',
    DISH_WASHER = 'Dish Washer'
}

export enum ImageType {
    DRAFT = 'DRAFT',
    RENDER = 'RENDER',
}

export type ObjectItem = {
    object_type: ObjectType,
    x: number,
    y: number,
    width: number,
    height: number,
}

export enum Handler {
    ROUTER = 'ROUTER',
    UNITY = 'UNITY',
    COMFY = 'COMFY',
    WALL_DETECTOR = 'WALL_DETECTOR',
    UE = 'UE',
    END = 'END',
    ERROR = 'ERROR',
}

export enum Series {
    UNITY = 'UNITY',
    UNITY_COMFY = 'UNITY_COMFY',
    UE = 'UE',
    UE_COMFY = 'UE_COMFY',
}

export type RawTask = {
    id: string;
    seed: number;
    date: string;
    step: Handler;
    queue: number;
    series: Series;
    name: string;
}

export class Task extends EventEmitter {
    #drawing: string = '';
    #src = '';
    #id: string;
    #step: Handler = Handler.ROUTER;
    private seed: number = Math.round(Math.random() * 10e15);
    #date: null | Moment = null;
    #queue = 0;
    private furniture: Array<ObjectItem> = [];
    #series: Array<Handler> = [Handler.ROUTER, Handler.WALL_DETECTOR, Handler.UNITY, Handler.UE, Handler.END];
    #seriesName: Series = Series.UE;
    private process = false;
    #name = 'project';

    constructor(id: string) {
        super();
        this.#id = id;
    }

    get drawing() {
        return this.#drawing;
    }

    get src() {
        return this.#src;
    }

    private set src(value: string) {
        this.#src = value;
        this.emit('src', value);
    }

    get name() {
        return this.#name;
    }

    set name(name: string) {
        this.#name = name;
        this.emit('name', name);
    }

    get id() {
        return this.#id;
    }

    get date(): Moment | null {
        return this.#date?.clone() || null;
    }

    get seriesName() {
        return this.#seriesName;
    }

    private set date(date: any) {
        this.#date = moment(date);
    }

    get step() {
        return this.#step;
    }

    private set step(value: Handler) {
        this.#step = value;
        this.emit('step', value);
    }

    get queue() {
        return this.#queue;
    }

    private set queue(value: number) {
        this.#queue = value;
        this.emit('queue', value);
    }

    get series() {
        return [...this.#series];
    }

    init(rawTask: RawTask) {
        this.queue = rawTask.queue;
        this.date = rawTask.date;
        this.seed = rawTask.seed;
        this.name = rawTask.name;
        this.changeSeries(rawTask.series);
        if (rawTask.step !== Handler.ROUTER) this.#drawing = `${config.baseUrl}/drawing/${this.id}`;
        this.job(rawTask);
        return this;
    }

    addDrawing(drawing: File) {
        if (this.process) return console.error('Запрещено менять чертеж во время обработки.');
        if (this.#drawing) URL.revokeObjectURL(this.#drawing);
        this.#drawing = URL.createObjectURL(drawing);
    }

    changeSeries(series: Series) {
        if (this.process) return console.error('Запрещено менять цепочку во время обработки.');
        switch (series) {
            case Series.UNITY_COMFY:
                this.#seriesName = series;
                return this.#series = [Handler.ROUTER, Handler.WALL_DETECTOR, Handler.UNITY, Handler.COMFY, Handler.END];
            case Series.UNITY:
                this.#seriesName = series;
                return this.#series = [Handler.ROUTER, Handler.WALL_DETECTOR, Handler.UNITY, Handler.END];
            case Series.UE:
                this.#seriesName = series;
                return this.#series = [Handler.ROUTER, Handler.WALL_DETECTOR, Handler.UNITY, Handler.UE, Handler.END];
            case Series.UE_COMFY:
                this.#seriesName = series;
                return this.#series = [Handler.ROUTER, Handler.WALL_DETECTOR, Handler.UNITY, Handler.UE, Handler.COMFY, Handler.END];
            default:
                return console.error(`Цепочка ${series} не реализована.`);
        }
    }

    async start() {
        if (this.process) return console.error('Процесс уже запущен.');
        const userId = $userId.getState();
        if (!userId) return console.error('Отсутствует id пользователя.');
        try {
            if (!this.#drawing) return console.error('Чертеж не загружен.');
            const blob = await fetch(this.drawing).then(res => res.blob());
            const file = new File([blob], `${this.id}.${blob.type.split('/').pop()}`, { type: blob.type });
            await uploadImage(file);
            const task = await createTask({ userId, id: this.id, seed: this.seed, series: this.#seriesName, name: this.#name });
            this.#date = moment(task.date);
            await startTask({ seed: this.seed, taskId: this.id, series: this.series });
            this.work();
        } catch (e) {
            console.error('Ошибка запуска задачи.');
            console.error(e);
        }
    }

    private async work() {
        while (!this.job(await loadTask(this.id))) await delay(1000);
    }

    private job(task: RawTask): boolean {
        try {
            if (task.step === Handler.ERROR) {
                this.step = Handler.ERROR;
                return true;
            }
            if (task.step === this.step) return false;

            this.step = task.step;
            this.queue = task.queue;

            if (!config.dev && this.step !== Handler.END) return false;

            const index = this.#series.findIndex(s => s === this.step);
            if (index < 0) {
                console.error('Unknown step.');
                return false;
            }
            switch (this.#series[index - 1]) {
                case Handler.COMFY:
                    this.src = `${config.baseUrl}/files/${this.id}_${Handler.COMFY}.png`;
                    break;
                case Handler.UE:
                    this.src = `${config.baseUrl}/files/${this.id}_${Handler.UE}.png`;
                    break;
                case Handler.WALL_DETECTOR:
                    this.src = `${config.baseUrl}/files/${this.id}_wall_mask.jpg`;
                    loadJSON(`${this.id}_furniture.json`).then(data => this.furniture = data);
                    break;
                case Handler.UNITY:
                    this.src = `${config.baseUrl}/files/${this.id}_${Handler.UNITY}.png`;
                    break;
                case Handler.ROUTER:
                    break;
                default:
                    console.error(`${this.#series[index - 1]} not implemented.`);
                    break;
            }

            if (this.#series[this.#series.length - 1] === this.step) return true;
            else return false;
        } catch (e) {
            console.error('Сбой в клиентской части.');
            console.error(e);
            return true;
        }
    }
}

export function useTask<T extends keyof Pick<Task, 'src' | 'drawing' | 'step' | 'name' | 'queue'>>(task: Task, key: T) {
    const [value, setValue] = useState(task[key]);

    useEffect(() => {
        task.addListener(key, setValue);
        return () => void task.removeListener(key, setValue);
    }, [task]);

    return value;
}