import { config } from "../config";
import { Objects } from "../entities";
import { Handler, RawTask, Series } from "../entities/task";

export function delay(ms: number) {
  return new Promise((res) => {
    setTimeout(res, ms);
  });
}

export async function createTask(data: {
  userId: string;
  id: string;
  seed: number;
  series: Series;
  name: string;
}): Promise<RawTask> {
  const response = await fetch(config.baseUrl + "/task", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.status !== 200) throw new Error("Нет ответа от сервера");
  if (!response.ok) throw new Error("Неизвестная ошибка.");
  return await response.json();
}

export async function startTask(data: {
  seed: number;
  taskId: string;
  series: Array<Handler>;
}): Promise<void> {
  const response = await fetch(config.baseUrl + "/task", {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.status !== 200) throw new Error("Нет ответа от сервера");
  if (!response.ok) throw new Error("Неизвестная ошибка.");
}

export async function loadTask(id: string): Promise<RawTask> {
  const response = await fetch(config.baseUrl + "/task/" + id);

  if (response.status !== 200) throw new Error("Нет ответа от сервера");
  if (!response.ok) throw new Error("Неизвестная ошибка.");
  return await response.json();
}

export async function uploadImage(file: File): Promise<void> {
  const formData = new FormData();
  formData.append("image", file);

  const response = await fetch(config.baseUrl + "/file", {
    method: "POST",
    body: formData,
  });

  if (!response.ok)
    throw new Error("Выгрузка изображения завершилась неудачей.");
}

export async function uploadJson(
  taskId: string,
  objects: Objects
): Promise<void> {
  const json = JSON.stringify({ objects });
  const blob = new Blob([json], { type: "application/json" });
  const formData = new FormData();
  formData.append("handler", blob, `furniture_${taskId}.json`);

  const response = await fetch(config.baseUrl + "/handler-file", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) throw new Error("Выгрузка файлы завершилась неудачей.");
}

export async function loadImage(path: string): Promise<File> {
  const url = `${config.baseUrl}/${path}`;
  const fileName = url.split("/").pop() || "undefined";
  const response = await fetch(url);
  if (!response.ok) throw new Error("Фото отсутствует.");
  const blob = await response.blob();
  const file = new File([blob], fileName, { type: blob.type });
  return file
}

export async function loadJSON(name: string): Promise<any> {
  const url = `${config.baseUrl}/files/${name}`;
  const response = await fetch(url);
  const body = await response.json();
  return body;
}

export async function loadHistory(userId: string): Promise<Array<RawTask>> {
  const response = await fetch(config.baseUrl + `/history/${userId}`);

  if (!response.ok) throw new Error("Выгрузка файлы завершилась неудачей.");
  const body = await response.json();
  return body;
}

export async function login(data: {
  login: string;
  password: string;
}): Promise<string> {
  const response = await fetch(config.baseUrl + "/login", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const body = await response.json();
  if (body.error) throw new Error(body.error);
  return body.id;
}

export async function loadStatus() {
  const response = await fetch(config.baseUrl + '/queue');
  const body = await response.json();
  if (body.error) throw new Error(body.error);
  return body;
}
