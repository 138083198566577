import React from 'react'

import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram_icon.svg'
import { ReactComponent as LinkedinIcon } from '../../assets/icons/linkedin_icon.svg'
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook_icon.svg'
import { ReactComponent as BeIcon } from '../../assets/icons/be_icon.svg'
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube_icon.svg'

const Footer = () => {
  return (
    <footer className='footer'>
      <p className='footer__copyright'>
        The View Pro Inc. <br className='new-line' /> ©{new Date().getFullYear()} All rights
        reserved
      </p>
      <div className='footer__wrapper'>
        <a href='#'>
          <InstagramIcon className='footer__icon' />
        </a>
        <a href='#'>
          <LinkedinIcon className='footer__icon' />
        </a>
        <a href='#'>
          <FacebookIcon className='footer__icon' />
        </a>
        <a href='#'>
          <BeIcon className='footer__icon' />
        </a>
        <a href='#'>
          <YoutubeIcon className='footer__icon' />
        </a>
      </div>
    </footer>
  )
}

export default Footer
