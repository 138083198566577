import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "effector-react";
import Navbar from "../../components/Navbar/Navbar";
import search from "../../assets/icons/search.svg";
import fileUploadIcon from "../../assets/icons/file_upload_icon.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron_down.svg";
import { $tasks, changeTask } from "../../entities";
import { Handler, Task, useTask } from "../../entities/task";
import { config } from "../../config";

interface ISortVariant {
  name: string;
  isSelected: boolean;
}

const MyProjects = () => {
  const [filter, setFilter] = useState<undefined | ((a: Task, b: Task) => number)>(() => fromNewToOld);
  const [isSortOpen, setIsSortOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState('');

  const [sortVariants, setSortVariants] = useState<ISortVariant[]>([
    {
      name: "From new to old",
      isSelected: true,
    },
    {
      name: "From old to new",
      isSelected: false,
    },
    {
      name: "By name",
      isSelected: false,
    },
  ]);
  const tasks = useStore($tasks);
  const navigate = useNavigate();

  const handleVariantClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string
  ) => {
    event.stopPropagation();

    setSortVariants((prev) =>
      prev.map((variant) =>
        variant.name === name
          ? { ...variant, isSelected: true }
          : { ...variant, isSelected: false }
      )
    );
    setIsSortOpen(false);
    switch (name) {
      case 'Recent':
        setFilter(undefined);
        break;
      case 'From new to old':
        setFilter(() => fromNewToOld);
        break;
      case 'From old to new':
        setFilter(() => fromOldToNew);
        break;
      case 'By name':
        setFilter(() => byName);
        break;
      default:
        console.error(`${name} not implemented.`);
        break;
    }
  };

  return (
    <>
      <div className="projects">
        <Navbar />
        <div className="projects__container">
          <h1 className="projects__title">My projects</h1>
          <div className="projects__inner-container">
            <div className="projects__files-wrapper">
              <div className="projects__input-wrapper">
                <input
                  type="text"
                  className="projects__files-search"
                  placeholder="Enter name of project"
                  value={searchString}
                  onChange={e => setSearchString(e.currentTarget.value)}
                />
                <img
                  src={search}
                  alt="search"
                  className="projects__search-icon"
                />
                <div
                  onClick={() => setIsSortOpen((prev) => !prev)}
                  className="projects__filter"
                >
                  {sortVariants.find((variant) => variant.isSelected)
                    ? sortVariants.find((variant) => variant.isSelected)!.name
                    : ""}{" "}
                  <ChevronDown />
                  {isSortOpen && (
                    <div className="projects__filter-menu">
                      {sortVariants.map(variant => (
                        !variant.isSelected && (
                          <div
                            onClick={event =>
                              handleVariantClick(event, variant.name)
                            }
                            key={variant.name}
                            className="projects__filter-name"
                          >
                            {variant.name}
                          </div>
                        )
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="projects__files-inner-wrapper">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/upload");
                  }}
                  className="projects__file-container"
                >
                  <span className="projects__create-new-plus">+</span>
                  <p className="projects__create-new-text">Create new</p>
                </span>
                {tasks.filter(task => filterStringsBySearch(task.name, searchString)).sort(filter).map((task) => (
                  <TaskElement key={task.id} task={task} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function filterStringsBySearch(string: string, searchString: string) {
  if (!searchString) return true;
  return string.includes(searchString);
}

function fromNewToOld(a: Task, b: Task): number {
  const date1 = a.date?.valueOf() || 0;
  const date2 = b.date?.valueOf() || 0;
  return date2 - date1;
}

function fromOldToNew(a: Task, b: Task): number {
  const date1 = a.date?.valueOf() || 0;
  const date2 = b.date?.valueOf() || 0;
  return date1 - date2;
}

function byName(a: Task, b: Task): number {
  return a.name.localeCompare(b.name);
}

function TaskElement({ task }: { task: Task }) {
  const step = useTask(task, 'step');
  const src = useTask(task, 'src');
  const drawing = useTask(task, 'drawing');
  const navigate = useNavigate();

  const done = useMemo(() => step === Handler.END, [step]);

  const toEdit = useCallback(() => {
    changeTask(task);
    navigate("/edit");
  }, [task]);

  const imageSrc = useMemo<string | undefined>(() => {
    if (src) return src.split('.').slice(0, -1).join('.') + '_100.jpg';
    if (drawing) return drawing;
    return;
  }, [src, drawing]);

  useEffect(() => {
    let tryed = false;
    const img = document.getElementById(task.id) as HTMLImageElement;
    if (!img) return console.error('Img not found.');
    function updateSource(ev: ErrorEvent) {
      if (tryed) return;
      img.src = src;
      tryed = true;
    }
    img.addEventListener('error', updateSource);
    return () => img.removeEventListener('error', updateSource);
  }, [src]);

  return (
    <div className="projects__file-container" onClick={done ? toEdit : () => { }}>
      <TaskStatus step={step} />
      <img
        className="projects__file-img img-spinner"
        alt="img"
        src={imageSrc}
        id={task.id}
      />
      <span className="projects__file-name">{task.name}</span>
      <div className="projects__file-inner-wrapper">
        <span className="projects__file-date">
          {task.date?.format("DD.MM.YYYY HH:mm")}
        </span>
        {(done || config.dev) && (
          <>
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
            </div>
            <a style={{ textDecoration: 'none', position: 'relative', top: -3, marginRight: 10, color: 'gray' }} href={`${config.baseUrl}/ue-files/${task.id}`} download>ue</a>
            <a href={`${config.baseUrl}/task-data/${task.id}`} download>
              <img
                style={{ cursor: "pointer" }}
                src={fileUploadIcon}
                alt="file-upload"
              />
            </a>
          </>
        )}
      </div>
    </div>

  )

}

export default MyProjects;

function TaskStatus({ step }: { step: Handler }) {
  const done = useMemo(() => step === Handler.END, [step]);

  if (step === Handler.ERROR) return (<span className="projects__file-status--error">Error</span>);
  if (done) return (<span className="projects__file-status--done">Done</span>);
  else return (<span className="projects__file-status--in-the-process">In the process</span>);
}
