import { createEffect, createEvent, createStore, sample } from "effector";
import { v4 as uuidv4 } from "uuid";
import { loadHistory, login } from "../services";
import { Task } from "./task";

export enum ObjectType {
  BATH = "Bath",
  DRYER = "Dryer",
  WASHING_MACHINE = "Washing Machine",
  COUNTERTOP = "Countertop",
  TOILET = "Toilet",
  STOVE = "Stove",
  REFRIGIRATOR = "Refrigirator",
  SINK = "Sink",
  KITCHEN_SINK = "Kitchen Sink",
  DISH_WASHER = "Dish Washer",
}

export type ObjectItem = {
  object_type: ObjectType;
  x: number;
  y: number;
  width: number;
  height: number;
};

export type Objects = Array<ObjectItem>;

export enum Handler {
  ROUTER = "ROUTER",
  UNITY = "UNITY",
  COMFY = "COMFY",
}

export const $userId = createStore<string | null>(null);
export const $task = createStore<Task>(new Task("11"));
export const $tasks = createStore<Array<Task>>([]);

export const changeTask = createEvent<Task>();
export const setUserId = createEvent<string | null>();
export const pushTask = createEvent<Task>();

export const loginFx = createEffect(login);
export const loadTasksFx = createEffect(loadHistory);

$userId.on(loginFx.doneData, (_, id) => id).on(setUserId, (_, id) => id);
$task.on(changeTask, (_, task) => task);
loginFx.doneData.watch(loadTasksFx);
loginFx.doneData.watch((value) => localStorage.setItem("userId", value));
$tasks
  .on(loadTasksFx.doneData, (_, tasks) =>
    tasks.map((task) => new Task(task.id).init(task))
  )
  .on(pushTask, (tasks, task) => [...tasks, task]);

sample({
  clock: $userId,
  filter: Boolean,
  target: loadTasksFx,
});

setUserId(localStorage.getItem("userId"));

export const tasks = new Map<string, Task>();
