import { useEffect, useMemo, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";

import { ReactComponent as RotateLeft } from "../../assets/icons/rotate_left.svg";
import { ReactComponent as RotateRight } from "../../assets/icons/rotate_right.svg";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron_left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron_right.svg";
import { ReactComponent as Upload } from "../../assets/icons/save_large_icon.svg";
import Menu from "./components/Menu";
import { useAppSelector } from "../../redux/hooks";
import FurnitureListModal from "./components/FurnitureListModal";
import FurnitureModal from "./components/FurnitureModal";
import ConfirmModal from "./components/ConfirmModal";
import { useStore } from "effector-react";
import { $task } from "../../entities";
import { useNavigate } from "react-router-dom";
import { ImageType, useTask } from "../../entities/task";
import ImageViewer from 'react-simple-image-viewer'

const EditPage = () => {
  const task = useStore($task);
  const src = useTask(task, 'src');
  const drawing = useTask(task, 'drawing');
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<ImageType>(() => src ? ImageType.RENDER : ImageType.DRAFT);
  const { isFurnitureListModalOpen, isFurnitureModalOpen, isConfirmModalOpen } =
    useAppSelector((state) => state.modal);
  const imageRef = useRef<HTMLImageElement>(null)
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault()
    setIsViewerOpen(true);
  }

  const closeImageViewer = (e?: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e && e.preventDefault()    
    setIsViewerOpen(false);
  };

  function loadRender() {
    if (!src) return;
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = src;
    a.download = task.name;

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(src);
    document.body.removeChild(a);
  }

  useEffect(() => {
    if (task.id === "11") navigate("/my-projects");
  }, []);

  const imageSrc = useMemo<string | undefined>(() => {
    switch (selected) {
      case ImageType.DRAFT:
        if (drawing) return drawing;
        else return;
      case ImageType.RENDER:
        return src;
      default:
        console.error(selected, ' is unknown.');
        return;
    }
  }, [src, drawing, selected]);

  return (
    <>
      <div className="edit">
        <Navbar />
        <div className="edit__container">
          <h1 className="edit__title">Your rendered 3D Plans</h1>
          <div className="edit__button-upper-wrapper">
            <button
              onClick={() => src && setSelected(ImageType.RENDER)}
              className={`btn ${selected === ImageType.RENDER ? "btn--selected" : ""}`}
            >
              Render
            </button>
            <button
              onClick={() => setSelected(ImageType.DRAFT)}
              className={`btn ${selected === ImageType.DRAFT ? "btn--selected" : ""}`}
            >
              Draft
            </button>
          </div>
          <div className="edit__inner-container">
            <div className="edit__buttons-wrapper">
              <div className="edit__menu"></div>
              <div className="edit__chevron" style={{ display: "none" }}>
                <ChevronLeft />
              </div>
              {/*               <div className="edit__rotate-wrapper">
                <RotateLeft />
                <RotateRight />
              </div> */}
            </div>
            <img className="img-spinner" src={imageSrc} ref={imageRef} onClick={(e) => openImageViewer(e)} />
            <div className="edit__buttons-wrapper">
              <div
                onClick={() => setIsMenuOpen((prev) => !prev)}
                className="edit__menu"
              >
                •••
                {isMenuOpen && <Menu />}
              </div>
              <div className="edit__chevron" style={{ display: "none" }}>
                <ChevronRight />
              </div>
              <div className="edit__rotate-wrapper" onClick={loadRender}>
                <Upload />
              </div>
            </div>
          </div>
          <p className="edit__bottom-text">
            If you want to create a render of a new layout, please click the "New Render" button.
          </p>
          <button onClick={() => navigate('/upload')} className="btn">New Render</button>
        </div>
        {isFurnitureListModalOpen && <FurnitureListModal />}
        {isFurnitureModalOpen && <FurnitureModal />}
        {isConfirmModalOpen && <ConfirmModal />}
      </div>
      {isViewerOpen && (
        <div className={selected === ImageType.DRAFT ? "mirror-image" : ''}>
        <ImageViewer
        src={imageRef.current ? [imageRef.current.src] : ['']}
        disableScroll={ false }
        closeOnClickOutside={ true }
        onClose={ closeImageViewer }
        backgroundStyle={{
          backgroundColor: "rgba(0,0,0,0.6)",
        }}
      />
        </div>
      )}
    </>
  );
};

export default EditPage;
