import React, { useEffect, useRef, useState } from "react";
import { useStore } from "effector-react";
import Navbar from "../../components/Navbar/Navbar";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow_right.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useOnClickOutside from "../../hooks/useClickOutside";
import { setIsRenameModalOpen } from "../../redux/slices/modalSlice";
import { $task } from "../../entities";
import { Series, useTask } from "../../entities/task";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_icon.svg";

const ProjectPreview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isRenameModalOpen } = useAppSelector((state) => state.modal);
  const task = useStore($task);
  const drawing = useTask(task, 'drawing');
  const [selectedButton, setSelectedButton] = useState(task.seriesName);
  const name = useTask(task, 'name');

  const handleClick = (series: Series) => {
    task.changeSeries(series);
    setSelectedButton(series);
  };

  useEffect(() => {
    if (task.id === "11") navigate("/my-projects");
  }, []);

  return (
    <>
      <Navbar />
      <div className="preview__container">
        <h1
          onClick={() =>
            dispatch(
              setIsRenameModalOpen({
                isRenameModalOpen: true,
              })
            )
          }
          className="preview__title"
        >
          Your {name} <EditIcon />
        </h1>
        <img className="preview__img" src={drawing} />
        <div className="preview__buttons-outer-wrapper">
          <h2 className="preview__buttons-title">Choose render type</h2>
          <div className="preview__buttons-inner-wrapper">
{/*             <button
              className={`btn ${selectedButton === Series.UE_COMFY ? "selected" : "not-selected"
                }`}
              onClick={() => handleClick(Series.UE_COMFY)}
            >
              AI Render (Unreal)
            </button>
            <button
              className={`btn ${selectedButton === Series.UNITY_COMFY ? "selected" : "not-selected"
                }`}
              onClick={() => handleClick(Series.UNITY_COMFY)}
            >
              AI Render (Unity)
            </button> */}
            <button
              className={`btn ${selectedButton === Series.UE ? "selected" : "not-selected"
                }`}
              onClick={() => handleClick(Series.UE)}
            >
              Unreal Render
            </button>
            <button
              className={`btn ${selectedButton === Series.UNITY ? "selected" : "not-selected"
                }`}
              onClick={() => handleClick(Series.UNITY)}
            >
              Unity Geometry
            </button>
          </div>
        </div>
        <div className="preview__bottom-navigation">
          <button
            onClick={() => {
              navigate("/upload");
            }}
            className="custom-btn"
          >
            <ArrowRightIcon /> BACK
          </button>
          <button
            onClick={() => {
              navigate("/choose-style");
            }}
            className="custom-btn"
          >
            NEXT <ArrowRightIcon />
          </button>
        </div>
      </div>
      {isRenameModalOpen && <RenameModal />}
    </>
  );
};

const RenameModal: React.FC = () => {
  const task = useStore($task);
  const [name, setName] = useState<string>(task.name);
  const { isRenameModalOpen } = useAppSelector((state) => state.modal);
  const modalRef = useRef(null);
  const dispatch = useAppDispatch();

  useOnClickOutside(modalRef, () =>
    dispatch(setIsRenameModalOpen({ isRenameModalOpen: false }))
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    task.name = name;
    dispatch(setIsRenameModalOpen({ isRenameModalOpen: false }));
  };

  return (
    <div
      style={{
        boxShadow: isRenameModalOpen
          ? "0px 0px 0px 1000px rgba(0, 0, 0, 0.5)"
          : "",
      }}
      className="projects__modal"
      ref={modalRef}
    >
      <div className="projects__modal-content">
        <h3 className="projects__modal-caption">NEW FOLDER NAME CREATION</h3>
        <h2 className="projects__modal-input-title">Folder name</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
            className="projects__modal-input"
          />
          <button type="submit" className="btn--sm">
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProjectPreview;
