import { SerializableFile } from "../redux/types";

export const validateFile = (file: File) => {
  const supportedFormats = [
    "image/jpeg",
    "image/png",
    "image/jpg",
  ];

  if (supportedFormats.includes(file.type)) {
    return { value: true, message: "" };
  } else {
    return {
      value: false,
      message:
        "This document is not supported, please delete and upload another file.",
    };
  }
};
