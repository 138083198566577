import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MobileFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <footer className="mobile-footer">
      <p className="mobile-footer__text">
        {" "}
        {location.pathname === "/sign-up"
          ? "Already have an account?"
          : "Doesn't have an account?"}
      </p>
      <span
        onClick={() =>
          location.pathname === "/sign-up"
            ? navigate("/sign-in")
            : navigate("/sign-up")
        }
        className="mobile-footer__link"
      >
        {location.pathname === "/sign-up" ? "Sign in" : "Sign up"}
      </span>
    </footer>
  );
};

export default MobileFooter;
