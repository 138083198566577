import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slices/authSlice'
import filesSlice from './slices/filesSlice'

import { saveState } from '../utils/localStorage'
import modalSlice from './slices/modalSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    files: filesSlice,
    modal: modalSlice,
  },
})

store.subscribe(() => {
  saveState(store.getState().files, 'files')
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
