import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ModalState {
  isRenameModalOpen?: boolean
  isConfirmModalOpen?: boolean
  isFurnitureModalOpen?: boolean
  isFurnitureListModalOpen?: boolean
}

const initialState: ModalState = {
  isRenameModalOpen: false,
  isConfirmModalOpen: false,
  isFurnitureModalOpen: false,
  isFurnitureListModalOpen: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setIsRenameModalOpen: (state, action: PayloadAction<ModalState>) => {
      state.isRenameModalOpen = action.payload.isRenameModalOpen
    },
    setIsConfirmModalOpen: (state, action: PayloadAction<ModalState>) => {
      state.isConfirmModalOpen = action.payload.isConfirmModalOpen
    },
    setIsFurnitureModalOpen: (state, action: PayloadAction<ModalState>) => {
      state.isFurnitureModalOpen = action.payload.isFurnitureModalOpen
    },
    setIsFurnitureListModalOpen: (state, action: PayloadAction<ModalState>) => {
      state.isFurnitureListModalOpen = action.payload.isFurnitureListModalOpen
    },
  },
})

export const {
  setIsRenameModalOpen,
  setIsConfirmModalOpen,
  setIsFurnitureListModalOpen,
  setIsFurnitureModalOpen,
} = modalSlice.actions

export default modalSlice.reducer
