import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IUserData {
  name: string
  email: string
  password: string
}

const initialState: IUserData = {
  name: '',
  email: '',
  password: '',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData: (state: IUserData, action: PayloadAction<IUserData>) => {
      state.name = action.payload.name
      state.email = action.payload.email
      state.password = action.payload.password
    },
  },
})

export const { setUserData } = authSlice.actions

export default authSlice.reducer
