import { useState, useEffect } from "react";
import Router from "./config/Router";
import MobileWarning from "./pages/mobileWarning/MobileWarning";
import { useStore } from "effector-react";
import { $userId } from "./entities";
import { useNavigate } from "react-router-dom";
import allRoutes from './config/routes/allRoutes.json';

const App = () => {
  const userId = useStore($userId);
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1000);

  const checkScreenSize = () => {
    setIsDesktop(window.innerWidth > 1000);
  };

  useEffect(() => {
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    if (userId) return;
    if (window.location.pathname === allRoutes.SignIn) return;
    if (window.location.pathname === allRoutes.SignUp) return;
    navigate(allRoutes.SignUp);
  }, [userId]);

  return <>{isDesktop ? <Router /> : <MobileWarning />}</>;
};

export default App;
