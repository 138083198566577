import React from "react";
import {
  setIsFurnitureListModalOpen,
  setIsFurnitureModalOpen,
} from "../../../redux/slices/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import furnitureImg5 from "../../../assets/images/furniture5.png";
import { ReactComponent as RotateIcon } from "../../../assets/icons/rotate_icon.svg";

const FurnitureModal = () => {
  const dispatch = useAppDispatch();
  const { isFurnitureModalOpen } = useAppSelector((state) => state.modal);

  return (
    <div className="furniture">
      <div
        style={{
          boxShadow: isFurnitureModalOpen
            ? "0px 0px 0px 1000px rgba(0, 0, 0, 0.5)"
            : "",
        }}
        className="furniture__container"
      >
        <div
          onClick={() =>
            dispatch(
              setIsFurnitureModalOpen({
                isFurnitureModalOpen: false,
              })
            )
          }
          className="close-btn"
        >
          ✖
        </div>
        <div className="furniture__inner-container">
          <img src={furnitureImg5} />
          <p className="furniture__text">COLOUR</p>
          <div className="furniture__inputs-wrapper">
            <div className="furniture__input-wrapper">
              <input type="checkbox" />
              <label className="furniture__input-name">Black</label>
            </div>
            <div className="furniture__input-wrapper">
              <input type="checkbox" />
              <label className="furniture__input-name">Green</label>
            </div>
            <div className="furniture__input-wrapper">
              <input type="checkbox" />
              <label className="furniture__input-name">Gray</label>
            </div>
            <div className="furniture__input-wrapper">
              <input type="checkbox" />
              <label className="furniture__input-name">Red</label>
            </div>
          </div>
          <p className="furniture__text">ROTATE</p>
          <div className="furniture__rotate-wrapper">
            <RotateIcon />
            <p className="furniture__rotate-text">0°</p>
          </div>
        </div>
        <button
          onClick={() => {
            dispatch(
              setIsFurnitureModalOpen({
                isFurnitureModalOpen: false,
              })
            );
            dispatch(
              setIsFurnitureListModalOpen({
                isFurnitureListModalOpen: true,
              })
            );
          }}
          className="btn"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default FurnitureModal;
