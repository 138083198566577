import React, { useState } from "react";

import furniture1 from "../../../assets/images/furniture1.png";
import furniture2 from "../../../assets/images/furniture2.png";
import furniture3 from "../../../assets/images/furniture3.png";
import furniture4 from "../../../assets/images/furniture4.png";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setIsConfirmModalOpen,
  setIsFurnitureListModalOpen,
  setIsFurnitureModalOpen,
} from "../../../redux/slices/modalSlice";

interface IFurniture {
  furniture: string[];
}

const FurnitureListModal = () => {
  const [furniture, setFurniture] = useState<IFurniture[]>([
    {
      furniture: [
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
        furniture1,
      ],
    },
    {
      furniture: [
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
        furniture2,
      ],
    },
    {
      furniture: [
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
        furniture3,
      ],
    },
    {
      furniture: [
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
        furniture4,
      ],
    },
  ]);
  const dispatch = useAppDispatch();
  const { isFurnitureListModalOpen } = useAppSelector((state) => state.modal);

  return (
    <div className="furniture-list">
      <div
        style={{
          boxShadow: isFurnitureListModalOpen
            ? "0px 0px 0px 1000px rgba(0, 0, 0, 0.5)"
            : "",
          zIndex: 999,
        }}
        className="furniture-list__container"
      >
        <div
          onClick={() =>
            dispatch(
              setIsFurnitureListModalOpen({
                isFurnitureListModalOpen: false,
              })
            )
          }
          className="close-btn"
        >
          ✖
        </div>
        {furniture.map((fur, index) => (
          <div key={index} className="furniture-list__outer-wrapper">
            <div className="furniture-list__inner-wrapper">
              {fur.furniture.map((f, index) => (
                <img
                  key={index}
                  src={f}
                  onClick={() => {
                    dispatch(
                      setIsFurnitureModalOpen({
                        isFurnitureModalOpen: true,
                      })
                    );
                    dispatch(
                      setIsFurnitureListModalOpen({
                        isFurnitureListModalOpen: false,
                      })
                    );
                  }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <button
        onClick={() => {
          dispatch(
            setIsConfirmModalOpen({
              isConfirmModalOpen: true,
            })
          );
          dispatch(
            setIsFurnitureListModalOpen({
              isFurnitureListModalOpen: false,
            })
          );
        }}
        className="btn"
      >
        Save
      </button>
    </div>
  );
};

export default FurnitureListModal;
