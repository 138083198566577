import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setIsConfirmModalOpen,
  setIsFurnitureListModalOpen,
} from "../../../redux/slices/modalSlice";

const ConfirmModal = () => {
  const dispatch = useAppDispatch();
  const { isConfirmModalOpen } = useAppSelector((state) => state.modal);

  return (
    <div className="confirm">
      <div
        style={{
          boxShadow: isConfirmModalOpen
            ? "0px 0px 0px 1000px rgba(0, 0, 0, 0.5)"
            : "",
        }}
        className="confirm__container"
      >
        <p className="confirm__top-text">FURNITURE CHANGE</p>
        <p className="confirm__text">
          This action requires a re-render. <br />
          You’ll have to wait
        </p>
        <div className="confirm__buttons-wrapper">
          <button
            onClick={() => {
              dispatch(
                setIsConfirmModalOpen({
                  isConfirmModalOpen: false,
                })
              );
            }}
            className="btn--sm"
          >
            Confirm
          </button>
          <button
            onClick={() => {
              dispatch(
                setIsConfirmModalOpen({
                  isConfirmModalOpen: false,
                })
              );
              dispatch(
                setIsFurnitureListModalOpen({
                  isFurnitureListModalOpen: true,
                })
              );
            }}
            className="btn--sm"
          >
            Denay
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
