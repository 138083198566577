import SignIn from '../../pages/registration/stages/SignIn'

import allRoutes from './allRoutes.json'
import SignUp from '../../pages/registration/stages/SignUp'
import Homepage from '../../pages/homepage/Homepage'
import DesignUpload from '../../pages/designUpload/DesignUpload'
import ChooseStyle from '../../pages/chooseStyle/ChooseStyle'
import RenderSent from '../../pages/renderSent/RenderSent'
import MyProjects from '../../pages/myProjects/MyProjects'
import ProjectPreview from '../../pages/projectPreview/ProjectPreview'
import EditPage from '../../pages/editPage/EditPage'
import Status from '../../pages/status/Status'

export const routesMap = {
  [allRoutes.SignIn]: <SignIn />,
  [allRoutes.SignUp]: <SignUp />,
  [allRoutes.Homepage]: <Homepage />,
  [allRoutes.DesignUpload]: <DesignUpload />,
  [allRoutes.ProjectPreview]: <ProjectPreview />,
  [allRoutes.ChooseStyle]: <ChooseStyle />,
  [allRoutes.RenderSent]: <RenderSent />,
  [allRoutes.MyProjects]: <MyProjects />,
  [allRoutes.EditPage]: <EditPage />,
  [allRoutes.Status]: <Status />
}
