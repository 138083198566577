import React from "react";

import welcomeImg1 from "../../assets/images/welcome_img1.png";
import welcomeImgMobile from "../../assets/images/welcome_img_mobile.png";
import welcomeImg2 from "../../assets/images/welcome_img2.png";
import step1 from "../../assets/images/step1.png";
import step2 from "../../assets/images/step2.png";
import step3 from "../../assets/images/step3.png";
import videoImg from "../../assets/images/video_img.png";
import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";
import gallery1 from "../../assets/images/gallery1.png";
import gallery2 from "../../assets/images/gallery2.png";
import gallery3 from "../../assets/images/gallery3.png";
import gallery4 from "../../assets/images/gallery4.png";
import gallery5 from "../../assets/images/gallery5.png";
import gallery6 from "../../assets/images/gallery6.png";
import gallery7 from "../../assets/images/gallery7.png";
import gallery8 from "../../assets/images/gallery8.png";
import gallery9 from "../../assets/images/gallery9.png";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow_right.svg";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { $userId } from "../../entities";

const Homepage = () => {
  const userId = useStore($userId);
  const navigate = useNavigate();

  return (
    <div className="homepage">
      <Navbar />
      <div className="homepage__container">
        <div className="homepage__welcome-container">
          <div className="homepage__welcome-wrapper">
            <h2 className="homepage__welcome-title">
              Hi, start your design <br /> creation
            </h2>
            <p className="homepage__welcome-body grey-text">
              Each 3D Floor Plan is designed to look like your on-site unit.
              <br />
              <br />
              You will receive unlimited rounds of changes and quality rended
              based on your layout.
            </p>
            <button
              onClick={() => {
                userId ? navigate("/upload") : navigate("/sign-up");
              }}
              className="btn"
            >
              Create a project
            </button>
          </div>
          <img src={welcomeImg1} className="homepage__welcome-img-desktop" />
          <img
            src={welcomeImgMobile}
            className="homepage__welcome-img-mobile"
          />
        </div>
        <div className="homepage__welcome-container welcome-grid-2">
          <div className="homepage__welcome-wrapper">
            <h2 className="homepage__welcome-title2">What is 3D Plan?</h2>
            <div className="homepage__welcome-img-wrapper-mobile">
              <img src={welcomeImg2} className="homepage__welcome-img" />
            </div>
            <p className="homepage__welcome-body2">
              <b>3D Plan</b> is an interior visualization platform that allows
              you to convert a layout of your apartment into a 3D render.
              <br />
              <br />
              <b>The main task</b> of 3D plan is to provide a simple and
              convenient tool for space planning and interior design within the
              TheViewVR.
            </p>
            <button className="custom-btn">
              LOAD THEVIEWVR <ArrowRightIcon />
            </button>
            <span className="homepage__welcome-section-title">ABOUT</span>
          </div>
          <img
            src={welcomeImg2}
            className="homepage__welcome-img first-position"
          />
        </div>
        <div className="homepage__steps-container">
          <h2 className="homepage__steps-title">How it works</h2>
          <div className="homepage__step-outer-container dark-bg">
            <div className="homepage__step-inner-container">
              <div className="homepage__step-wrapper">
                <span className="homepage__step-count">STEP 1</span>
                <h2 className="homepage__step-title">Upload your layout</h2>
                <p className="homepage__step-body">
                  You can use any types of layouts either professional design or
                  low quality sketches. Don’t forget to fill out the form
                  indicating stylistic features
                </p>
              </div>
              <img src={step1} className="homepage__step-img" />
            </div>
          </div>
          <div className="homepage__step-outer-container light-bg">
            <div className="homepage__step-inner-container step-grid-2">
              <div className="homepage__step-wrapper">
                <span className="homepage__step-count">STEP 2</span>
                <h2 className="homepage__step-title">Arrange the furniture</h2>
                <p className="homepage__step-body">
                  Use the base points on the object to arrange the furniture you
                  need. Choose needed furniture from our library.
                </p>
              </div>
              <img src={step2} className="homepage__step-img" />
            </div>
          </div>
          <div className="homepage__step-outer-container dark-bg">
            <div className="homepage__step-inner-container">
              <div className="homepage__step-wrapper">
                <span className="homepage__step-count">STEP 3</span>
                <h2 className="homepage__step-title">Get your render</h2>
                <p className="homepage__step-body">
                  The program will automatically download your 3d render. You
                  can use the first picture or continue arranging furniture and
                  render again
                </p>
              </div>
              <img src={step3} className="homepage__step-img" />
            </div>
          </div>
        </div>
        <div className="homepage__video-container">
          <h2 className="homepage__video-title">Our video manual</h2>
          <img src={videoImg} className="homepage__video" />
          <PlayIcon className="homepage__play-icon" />
        </div>
        <div className="homepage__gallery-container">
          <h2 className="homepage__gallery-title">Gallery</h2>
          <div className="homepage__gallery-grid-1">
            <img src={gallery1} className="homepage__gallery-img" />
            <img src={gallery2} className="homepage__gallery-img" />
            <img src={gallery3} className="homepage__gallery-img" />
            <img src={gallery4} className="homepage__gallery-img" />
          </div>
          <div className="homepage__gallery-grid-2">
            <img src={gallery5} className="homepage__gallery-img" />
            <img src={gallery6} className="homepage__gallery-img" />
            <img src={gallery7} className="homepage__gallery-img" />
            <img src={gallery8} className="homepage__gallery-img" />
            <img src={gallery9} className="homepage__gallery-img" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
