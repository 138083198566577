import React from "react";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";

const MobileWarning = () => {
  return (
    <div className="mobile-warning">
      <div className="mobile-warning__container">
        <Logo />
        <h2 className="mobile-warning__text">Mobile version coming soon</h2>
      </div>
    </div>
  );
};

export default MobileWarning;
