import { useEffect, useMemo } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { useNavigate } from 'react-router'

import { ReactComponent as OkayIcon } from '../../assets/icons/okay.svg'
import loading from '../../assets/icons/spinner.gif'
import { $task } from '../../entities'
import { useStore } from 'effector-react'
import { Handler, useTask } from '../../entities/task'
import { config } from '../../config'

const RenderSent = () => {
  const navigate = useNavigate();
  const task = useStore($task);
  const step = useTask(task, 'step');
  const queue = useTask(task, 'queue');

  useEffect(() => {
    if (step === Handler.END) navigate('/edit');
  }, [step]);

  useEffect(() => {
    if (step === Handler.ERROR) navigate('/my-projects');
  }, [step]);

  return (
    <div className='sent'>
      <Navbar />
      <div className='sent__container'>
        <div className='sent__inner-container'>
          {
            config.dev
              ?
              <Pipeline />
              :
              <Thanks />
          }
          {
            task.series.map((handler, index) => {
              if ([Handler.ROUTER, Handler.END].includes(handler)) return null;
              const currentIndex = task.series.findIndex(handler => handler === step);
              const done = index < currentIndex;
              if (step === handler) return (
                <p className='sent__stage' key={handler}>
                  <img src={loading} alt='My Gif' /> {handler} {queue > 1 ? `(in progress) Your place in queue ${queue}` : '(in progress)'}
                </p>
              );
              return (
                <p className='sent__stage' key={handler}>
                  {
                    done
                      ?
                      (<><OkayIcon /> {handler} (done)</>)
                      :
                      (
                        <>
                          {' '}
                          <img src={loading} alt='My Gif' /> {handler} (waiting)
                        </>
                      )
                  }
                </p>
              );
            })
          }
          <button onClick={() => navigate('/my-projects')} className='btn'>
            My projects
          </button>
        </div>
      </div>
    </div>
  )
}

export default RenderSent


function Thanks() {
  return (
    <>
      <h1 className='sent__title'>Thanks!</h1>
      <h2 className='sent__body'>
        Please wait for your layout to be processed to get the render.<br /> You can close this page
        and track progress on the My Projects.
      </h2>
    </>
  );
}

function Pipeline() {
  const task = useStore($task);
  const src = useTask(task, 'src');
  const drawing = useTask(task, 'drawing');

  const imageSrc = useMemo<string | undefined>(() => {
    if (src) return src;
    if (drawing) return drawing;
    return;
  }, [src, drawing]);

  return (
    <div style={{
      maxHeight: '50vh',
    }}>
      <img id="rendersent" src={imageSrc} style={{
        height: '100%',
        width: '100%',
        maxHeight: '50vh',
      }} />
    </div>
  );
}